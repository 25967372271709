import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { initialForgotPasswordFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/ResetPasswordPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/Logo.svg';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { colors } from 'theme/colors';
import { usePassword } from 'models/passwordContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { useNavigate } from 'react-router-dom';
import { AuthTemplate } from 'components/templates/AuthTemplate';
import { IChangePasswordBodyRequest } from 'models/password';
import { useQuery } from 'hooks/useQuery';
import { StyledPasswordRulesWrapper } from 'pages/ResetPasswordPage/styles';
import { validateResetPasswordLink } from 'requests/auth';
import { FC_SYSTEM_DEVICE_ID } from 'pages/LoginPage';

const ResetPasswordPage = (): ReactElement => {
  const [isResetPasswordSuccessfully, setIsResetPasswordSuccessfully] = useState<
    boolean | null
  >(null);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialForgotPasswordFormValues,
    ...(isResetPasswordSuccessfully === null ? { resolver: yupResolver(schema) } : {}),
  });
  const { changePassword, isChangePasswordLoading, isForgotPasswordLoading } = usePassword();

  const query = useQuery();
  const token: any = query.get('token');
  const queryParams: any = new URLSearchParams({
    token: token.toUpperCase(),
  });

  const encodedEmail: any = query.get('e');
  let decodedEmail = '';

  try {
    decodedEmail = encodedEmail ? atob(encodedEmail?.toString()) : '';
  } catch (e: any) {
    navigate(ERouteLinks.Auth);
  }

  useEffect(() => {
    (async function callValidateResetPasswordLink() {
      try {
        await validateResetPasswordLink(token);
      } catch (e: any) {
        setIsResetPasswordSuccessfully(false);
        if (e.response.status === 410) {
          setResetPasswordErrorMessage(
            'The reset password link has expired. To reset your password, click on the button below.',
          );
        } else if (e.response.status === 400) {
          setResetPasswordErrorMessage('The reset password link has already been used.');
        }
        /* eslint-disable no-empty */
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePasswordSubmit = handleSubmit(
    ({ newPassword }: IChangePasswordBodyRequest) => {
      changePassword(
        { newPassword },
        queryParams,
        (res) => {
          localStorage.removeItem(FC_SYSTEM_DEVICE_ID);
          if (res.accessToken) {
            setIsResetPasswordSuccessfully(true);
          } else {
            navigate(ERouteLinks.Auth, {
              state: {
                isRedirectToTwoFactorAuthPage: true,
                email: decodedEmail,
                password: newPassword,
              },
            });
          }
        },
        (errorMessage: string) => {
          setIsResetPasswordSuccessfully(false);
          setResetPasswordErrorMessage(errorMessage);
        },
      );
    },
  );

  const handleForgotPasswordSubmit = () => {
    navigate(ERouteLinks.ForgotPassword);
  };

  const renderResetPasswordTemplate = () => {
    if (isResetPasswordSuccessfully === null) {
      return (
        <>
          <Grid
            item
            xs={12}
            sx={{
              m: {
                xs: 2,
                sm: 3,
              },
            }}
          >
            <Grid item>
              <Typography variant="h4" textAlign="center" mb={2}>
                Set New Password
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" textAlign="center">
                Your new password must have:
              </Typography>
              <StyledPasswordRulesWrapper>
                <li>
                  <Typography variant="caption">between 8 and 64 characters</Typography>
                </li>
                <li>
                  <Typography variant="caption">
                    at least one uppercase and special character
                  </Typography>
                </li>
              </StyledPasswordRulesWrapper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <FormInput
                name="newPassword"
                type={EFormInputTypes.password}
                label="New Password"
                placeholder="Enter new password"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
            </Box>
            <FormInput
              name="newPasswordRepeat"
              type={EFormInputTypes.password}
              label="Confirm New Password"
              placeholder="Password"
              control={control}
              errors={errors}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: { xs: 32, sm: 40 },
                },
              }}
            />
          </Grid>
        </>
      );
    }
    if (isResetPasswordSuccessfully) {
      return (
        <>
          <Grid item xs={12} mt={12} mb={8}>
            <Typography variant="h4" textAlign="center" mb={2}>
              Password Reset
            </Typography>
          </Grid>
          <Grid item mb={10}>
            <Alert text="The password has been changed successfully." />
          </Grid>
          <Grid item xs={12} sm={7} xl={12} container>
            <Button
              label="Continue"
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.Licences)}
              fullWidth
            />
          </Grid>
        </>
      );
    }
    if (!isResetPasswordSuccessfully) {
      return (
        <>
          <Grid item m={8}>
            <Typography variant="h4" textAlign="center" mb={2}>
              Set New Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Alert text={resetPasswordErrorMessage} variant={EAlertVariants.warning} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={12} container justifyContent="center">
            <Grid item xs={12} my={3}>
              <Button
                label="Resend Link"
                variant={EButtonVariants.outlined}
                onClick={() => handleForgotPasswordSubmit()}
                isLoading={isForgotPasswordLoading}
                fullWidth
              />
            </Grid>
            <RouterLink to={ERouteLinks.Auth} label="Back to log in" />
          </Grid>
        </>
      );
    }
    return null;
  };

  return (
    <AuthTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sm={10}
        md={6}
        lg={5}
        xl={6}
        sx={{ pt: { xs: 3 }, pb: { xs: 3, sm: 6 } }}
        px={2}
      >
        <Grid item xs={12} xl={6} container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            sx={{ width: { xs: '120px', sm: '200px', xl: '240px' } }}
          >
            <Logo src={LogoImage} alt="Logo" />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography
              variant="button"
              textTransform="uppercase"
              color={colors.primary500}
              my={1}
            >
              Manager
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            {renderResetPasswordTemplate()}
          </Grid>
          {isResetPasswordSuccessfully === null && (
            <Grid item xs={12} sm={7} xl={12} container justifyContent="center">
              <Grid item xs={12} my={3}>
                <Button
                  label="Set new password"
                  variant={EButtonVariants.contained}
                  onClick={() => handleChangePasswordSubmit()}
                  isLoading={isChangePasswordLoading}
                  fullWidth
                />
              </Grid>
              <RouterLink to={ERouteLinks.Auth} label="Back to log in" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { ResetPasswordPage };
