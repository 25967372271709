import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { initialTranslationFormValues, ITranslationFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Grid, Typography } from '@mui/material';
import { useTranslations } from 'pages/TranslationsPage/hooks';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/system';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TranslationForm } from 'pages/TranslationsPage/TranslationForm';
import { downloadFile } from 'requests/translation';
import { StyledDetail } from '../styles';

const EditTranslationPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [initialValues, setInitialValues] = useState<ITranslationFormValues | null>();
  const { handleTranslationEdit, translation, getTranslationData } = useTranslations();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    if (!longDateFormat) {
      getDateFormat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getDataInit() {
      await getTranslationData(id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (translation) {
      setInitialValues({
        translationsFile: translation?.fileName,
        name: translation?.name,
        nativeName: translation?.nativeName,
        uploadedFile: '',
      });
    }
  }, [translation]);

  const handleSubmit = async (data: ITranslationFormValues) => {
    try {
      await handleTranslationEdit(id, data);
      navigate(ERouteLinks.Translations);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  useEffect(() => {
    const formInitialValues = { ...initialTranslationFormValues };
    setInitialValues(formInitialValues);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Translations', route: ERouteLinks.Translations },
          'Edit Translation',
        ]}
      />
      <StyledPageTitle variant="h4">Edit Translation</StyledPageTitle>
      <Typography variant="body2" sx={{ margin: '10px 0' }}>
        {`Download the System Keys – Translation File with the complete list of keys and their translations.
        Then, in the downloaded file, in the "TRANSLATION" column, add the translation edits in a specific language.
        Then upload the translated file below and click save.
        Important! Make translation updates carefully as the existing key is currently being used in the system.`}
      </Typography>

      {translation && (
        <StyledDetail item container xs={12} rowSpacing={2}>
          <Grid item xs={12} lg={4} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              File Name
            </Typography>
            <Typography variant="body2">{translation.fileName ?? '-'}</Typography>
          </Grid>
          <Grid item xs={6} lg={4} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Last Update
            </Typography>
            <Typography variant="body2">
              {translation.lastModifiedDate
                ? formatDate(translation.lastModifiedDate, longDateFormat)
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={4} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Updates Author
            </Typography>
            <Typography variant="body2">{translation.lastModifiedBy ?? '-'}</Typography>
          </Grid>
        </StyledDetail>
      )}

      <Grid item container xs={12} lg={8}>
        <Button
          fullWidth
          variant={EButtonVariants.outlined}
          icon={EIconTypes.download}
          label="download system keys csv file"
          onClick={() => {
            downloadFile(
              id,
              translation?.fileName || `${translation?.name.toLowerCase()}.csv`,
              true,
            );
          }}
        />
      </Grid>

      <TableFormWrapper>
        <TranslationForm
          onCancel={() => navigate(ERouteLinks.Translations)}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
          isEdit={true}
        />
      </TableFormWrapper>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { EditTranslationPage };
